import React, { useEffect } from "react";
import Portfolio1 from '../../assets/spiderlegs.webp';
import Portfolio2 from '../../assets/joyride.webp';
import Portfolio3 from '../../assets/github.webp';
import AOS from "aos";
import "aos/dist/aos.css";

const logos = [
    { 
        src: 'https://bilebandijoyride.com/wp-content/uploads/2024/06/Facelogo.jpg', 
        text: 'Bilebändi OMG logo',
        link: 'https://www.facebook.com/Bilebandi0MG' // Linkki ulkoiseen kohteeseen
    },
    { 
        src: 'https://bilebandijoyride.com/wp-content/uploads/2024/06/Joyriden-logo.webp', 
        text: 'Bilebändi Joyriden logo',
        link: 'https://bilebandijoyride.com/' // Linkki ulkoiseen kohteeseen
    },
    { 
        src: 'https://bilebandijoyride.com/wp-content/uploads/2024/06/Napatoiston-logo.webp', 
        text: 'Punkbändi Napatoiston logo',
        link: 'https://soundcloud.com/napatoisto-yhtye' // Linkki ulkoiseen kohteeseen
    },
    { 
        src: 'https://bilebandijoyride.com/wp-content/uploads/2024/06/Pro-green-logo.webp', 
        text: 'Asiakastyö logo',
        link: 'https://www.taitotalo.fi/ajankohtaista/hankkeet-ja-projektit-taitotalossa/kansainvalinen-toiminta-erasmushankkeet/erasmus-ka-210-vet-pro-green-way-arts-and-crafts' // Linkki ulkoiseen kohteeseen
    },
    { 
        src: 'https://bilebandijoyride.com/wp-content/uploads/2024/06/spiderlegs.webp', 
        text: 'Spiderlegs productions logo',
        link: 'https://spiderlegsproductions.com/' // Linkki ulkoiseen kohteeseen
    },
    { 
        src: 'https://bilebandijoyride.com/wp-content/uploads/2024/08/Keijot.webp', 
        text: 'Kouluprojekti logo',
        link: '' // Ei linkkiä tässä tapauksessa
    },
    { 
        src: 'https://bilebandijoyride.com/wp-content/uploads/2024/08/Sivusilmalla_logo.webp', 
        text: 'Kouluprojekti/Yritystoiminnan suunnittelu logo',
        link: '' // Ei linkkiä tässä tapauksessa
    },
    { 
        src: 'https://bilebandijoyride.com/wp-content/uploads/2024/08/ecocreative.webp', 
        text: 'Kouluprojekti/Kuvitteellisen yrityksen logo',
        link: '' // Ei linkkiä tässä tapauksessa
    }
];


const Works = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: "ease-out-cubic",
        });
    }, []);
    
    return (
        <section data-aos="fade-up" id='works'>

            <h2 title="Portfolio" className="worksTitle">Portfolio</h2>

            <span title="Tässä osiossa esittelen omien verkkosivujeni lisäksi myös tekemiäni videoeditointeja ja grafiikkaa, jotka ovat näkyneet sekä omilla sivuillani että tilauksesta valmistamillani asiakastöillä." className="workDesc">Tässä osiossa esittelen omien verkkosivujeni lisäksi myös tekemiäni videoeditointeja ja grafiikkaa, jotka ovat näkyneet sekä omilla sivuillani että tilauksesta valmistamillani asiakastöillä.</span>

            <h3 title="Verkkosivut" className="web-pages">Verkkosivut</h3>

            <div className="worksImgs">

                {/* Ensimmäinen työ */}
                <div className="work-item">
                    <a href="https://spiderlegsproductions.com/" target="_blank" rel="noreferrer">
                        <img
                        src={Portfolio1}
                        title="Spiderlegs productions yrityksen verkkosivu | React, Vite & Tailwind CSS"
                        alt="Spiderlegs productions verkkosivut"
                        className="worksImg"
                        loading="lazy"
                        width="512"
                        height="512"
                        />
                    </a>
                    <span
                        title="Äänentoiston- ja esitystekniikan vuokrausyritykseni verkkosivut, jota pyöritän sivutoimisesti. Sivuston kehityksessä on hyödynnetty Reactia, Vitea ja Tailwind CSS -teknologioita."
                        className="site-desc"
                    >
                        <b>Spiderlegs productions</b><br/><br />
                        Äänentoiston- ja esitystekniikan vuokrausyritykseni verkkosivut, jota pyöritän sivutoimisesti. Sivuston kehityksessä on hyödynnetty Reactia, Vitea ja Tailwind CSS -teknologioita.<br/><br/>
                        Kuvaa klikkaamalla pääset vierailemaan sivustolla.
                    </span>
                </div>

                {/* Toinen työ */}
                <div className="work-item2">
                    <a href="https://bilebandijoyride.com/" target="_blank" rel="noreferrer">
                        <img
                        src={Portfolio2}
                        title="Bilebändi Joyride verkkosivut | Wordpress & Bootstrap"
                        alt="Bilebändi Joyride verkkosivut"
                        className="worksImg"
                        loading="lazy"
                        width="512"
                        height="512"
                        />
                    </a>
                    <span
                        title="Bilebändi Joyriden verkkosivut ovat intohimoni musiikkia kohtaan konkreettisessa muodossa. Erityisesti rumpujensoitto ja studiotyöskentely ovat lähellä sydäntäni.Tämä yksisivuinen sivusto on toteutettu WordPress-alustalle, hyödyntäen omaa teemaa ja Bootstrap-kehystä."
                        className="site-desc"
                    >
                        <b>Bilebändi Joyride</b><br/><br />
                        Bilebändi Joyriden verkkosivut ovat intohimoni musiikkia kohtaan konkreettisessa muodossa. Erityisesti rumpujensoitto ja studiotyöskentely ovat lähellä sydäntäni. <br/><br/>Tämä yksisivuinen sivusto on toteutettu WordPress-alustalle, hyödyntäen omaa teemaa ja Bootstrap-kehystä. Kuvaa klikkaamalla pääset vierailemaan sivustolla.
                    </span>
                </div>

                {/* Kolmas työ */}
                <div className="work-item3">
                    <a href="https://arttustenius.github.io/" target="_blank" rel="noreferrer">
                        <img
                            src={Portfolio3}
                            title="Arttu Stenius digitaalinen CV | HTML, CSS, JavaScript & Bootstrap"
                            alt="Arttu Stenius github page"
                            className="worksImg"
                            loading="lazy"
                            width="512"
                            height="512"
                        />
                    </a>
                    <span
                        title="Tämä yksisivuinen sivu toimii digitaalisena ansioluettelona GitHubissa. Se on rakennettu käyttäen HTML:ää, CSS:ää, JavaScriptiä ja Bootstrap-kehystä."
                        className="site-desc"
                    >
                        <b>Github digitaalinen CV</b><br/><br />
                        Tämä yksisivuinen sivu toimii digitaalisena ansioluettelona GitHubissa. Se on rakennettu käyttäen HTML:ää, CSS:ää, JavaScriptiä ja Bootstrap-kehystä. <br/><br/>
                        Kuvaa klikkaamalla pääset vierailemaan sivustolla.
                    </span>
                </div>

            </div>

            <h3 title="Verkkosivut" className="web-pages">Videoeditointi</h3>

            <div data-aos="fade-in" data-aos-duration="1000" className="youtubevids">
                {/* YouTube-videot */}
                <iframe 
                    width="560" 
                    height="315" 
                    src="https://www.youtube.com/embed/nE5wx_BZWZ0?si=22s__VOCa_ATCqXN" 
                    title="Tämä video on leikattu 4 eri action-kameran materiaalista. Musiikki videolla on tuotu suoraan live-tilanteen tallenteesta, joka nauhoitettiin myös samaan aikaan. Video on kuvattu 6.7.2023 Ravintola Saaristossa Helsingissä. Kyseinen kokoonpano ei ole enää olemassa. Bändissä vaikuttavat yhä perustajajäsenet rumpali Arttu ja basisti Teemu. Video on editoitu Corel Video Studiolla ja musiikki tuotettu PreSonuksen Studio Onella. Bändi jatkaa toimintaansa triona ja on edelleen aktiivinen." 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    referrerPolicy="strict-origin-when-cross-origin" 
                    allowFullScreen
                    style={{ marginRight: '10px', marginBottom: '10px' }}
                ></iframe>

                <iframe 
                    width="560" 
                    height="315" 
                    src="https://www.youtube.com/embed/7MPRXiMVJJo?si=gc4X_uofB-REKjCN" 
                    title="Tämä video on leikattu 4 eri action-kameran materiaalista. Musiikki videolla on tuotu suoraan live-tilanteen tallenteesta, joka nauhoitettiin myös samaan aikaan. Video on kuvattu 6.7.2023 Ravintola Saaristossa Helsingissä. Kyseinen kokoonpano ei ole enää olemassa. Bändissä vaikuttavat yhä perustajajäsenet rumpali Arttu ja basisti Teemu. Video on editoitu Corel Video Studiolla ja musiikki tuotettu PreSonuksen Studio Onella. Bändi jatkaa toimintaansa triona ja on edelleen aktiivinen." 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    referrerPolicy="strict-origin-when-cross-origin" 
                    allowFullScreen
                    style={{ marginRight: '10px', marginBottom: '10px' }}
                ></iframe>

                <iframe 
                    width="560" 
                    height="315" 
                    src="https://www.youtube.com/embed/Nh46J4hoiSE?si=ay1kJxAA6XuzxDBZ" 
                    title="Tämä video on äänitetty treenikämpällä kännykällä ja editoitu sitten Corel Video Studiolla. Musiikki on erotettu videosta ja käsitelty sekä miksattu uudelleen Presonuksen Studio Onella." 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                    style={{ marginRight: '10px', marginBottom: '10px' }}
                ></iframe>

                <iframe 
                    width="560" 
                    height="315" 
                    src="https://www.youtube.com/embed/bHVq9o7ktDc?si=j0c_unzMyaTloT6e" 
                    title="Tämä video on vanha ja tehty iMacin videoeditointiohjelmistolla. Se esittelee legendaarisen Napatoisto yhtyeen punk-henkisen kappaleen totaaliin." 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                    style={{ marginRight: '10px', marginBottom: '10px' }}
                ></iframe>

                <iframe 
                    width="560" 
                    height="315" 
                    src="https://www.youtube.com/embed/AjVsYdQ9mAo?si=boue76krKLpCWNIu" 
                    title="Tämä on Spiderlegs productionsin valopromovideo." 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                    style={{ marginRight: '10px', marginBottom: '10px' }}
                ></iframe>
                <iframe 
                    width="560" 
                    height="315" 
                    src="https://www.youtube.com/embed/xYuvqijBV9M?si=tQGVetEM7GUok0LM" 
                    title="Tämä on punkbändi Korjosen Annelin ensimmäinen biisi ja video" 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                    style={{ marginRight: '10px', marginBottom: '10px' }}
                ></iframe>

            </div>

            <h3 title="Kuvankäsittely" className="web-pages">Kuvankäsittely</h3>
            <p title="Logot">Logot</p>

            <div className="logo-container">
                {logos.map((logo, index) => (
                    <div className="logo-item" key={index}>
                        {logo.link ? (
                            <a href={logo.link} target="_blank" rel="noreferrer">
                                <img
                                    src={logo.src}
                                    alt={logo.text}
                                    className="logo-img"
                                />
                            </a>
                        ) : (
                            <img
                                src={logo.src}
                                alt={logo.text}
                                className="logo-img"
                            />
                        )}
                        <div className="zoom" style={{ display: 'none' }}></div> {/* Suurennuslasin alustus */}
                        <p>{logo.text}</p>
                    </div>
                ))}
            </div>
        </section>
    )
}

export default Works;
