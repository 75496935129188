import React, { useRef, useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Html from '../../assets/html-icon.webp';
import Css from '../../assets/css-icon.webp';
import Javascript from '../../assets/javascript-icon.webp';
import Php from '../../assets/php-icon1.webp';
import Facebook from '../../assets/fb-icon.webp';
import Instagram from '../../assets/instagram-logo.webp';
import Linkedin from '../../assets/linkedin.webp';
import Github from '../../assets/githubicon.webp';
import emailjs from '@emailjs/browser';
import AOS from "aos";
import "aos/dist/aos.css";
import './Contact.css'; // Oletetaan, että olet luonut erillisen CSS-tiedoston

const Contact = () => {
    const [captchaVerified, setCaptchaVerified] = useState(false);

    useEffect(() => {
        AOS.init({
          duration: 1000,
          easing: "ease-out-cubic",
        });
        emailjs.init('YOUR_PUBLIC_KEY'); // Replace with your EmailJS public key
    }, []);

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        if (!captchaVerified) {
            alert("Ole hyvä ja varmista CAPTCHA.");
            return;
        }

        emailjs.sendForm('service_c61aduk', 'template_noku30n', form.current, 'XQMrkTGxtesOUrCdz')
            .then((result) => {
                console.log(result.text);
                e.target.reset();
                setCaptchaVerified(false); // Reset captcha verification
                alert('Sähköposti lähetetty! Kiitos viestistäsi.');
            }, (error) => {
                console.log(error.text);
                alert('Sähköpostin lähettäminen epäonnistui, yritä myöhemmin uudelleen.');
            });
    };

    const onCaptchaChange = (value) => {
        console.log("Captcha value:", value);
        setCaptchaVerified(true);
    };

    return (
        <section id="contactPage">
            <div id="languages">
                <h2 title="Kielet" className="contactPageTitle">Kielet</h2>
                <p title="Olen hyödyntänyt mm. seuraavia ohjelmointikieliä projekteissani." className="languagesDesc">
                    Olen hyödyntänyt mm. seuraavia ohjelmointikieliä projekteissani.
                </p>
                <div data-aos="zoom-in" data-aos-duration="3000" className="languagesImgs">
                    <img src={Html} alt="HTML Logo" className="languagesImg" loading="lazy" title="HTML" />
                    <img src={Css} alt="CSS Logo" className="languagesImg" loading="lazy" title="CSS" />
                    <img src={Javascript} alt="JavaScript Logo" className="languagesImg" loading="lazy" title="Javascript" />
                    <img src={Php} alt="PHP Logo" title="PHP" className="languagesImg" loading="lazy" />
                </div>
            </div>
        
            <section id="contact">
                <div>
                    <h2 title="Ota yhteyttä!" className="contactPageTitle">Ota yhteyttä</h2>
                    <span title="Ole hyvä ja täytä alla oleva lomake, vastaan viestiisi mahdollisimman pian." className="contactDesc">
                        Ole hyvä ja täytä alla oleva lomake, vastaan viestiisi mahdollisimman pian.
                    </span>
                    <br /><br />
                    <h3 title="Yhteydenottolomake">Yhteydenottolomake</h3>
                    
                    <form data-aos="zoom-in" data-aos-duration="3000" className='contactForm' ref={form} onSubmit={sendEmail}>
                        <input title="Kirjoita nimesi" type="text" className="name" placeholder='Nimesi..' name='user_name' required />
                        <input title="Syötä sähköpostisi" type="email" className="email" placeholder='Sähköpostisi..' name='user_email' required />
                        <textarea title="Kirjoita viestisi" className='msg' name="message" rows="5" placeholder="Kirjoita viestisi.." required></textarea>
                        
                        <ReCAPTCHA
                            sitekey="6LeycB8pAAAAAGt5my14x_nciAgN_BNJtyjrhQS2" // Replace with your reCAPTCHA site key
                            onChange={onCaptchaChange}
                        />
                        
                        <input type='submit' value='Lähetä' id='input-submit' className="submitBtn" />
                        <div className="links">
                            <a href="https://www.facebook.com/ittukikerava/" target="_blank" rel="noreferrer" title="IT-tuki Kerava, seuraa minua!">
                                <img src={Facebook} alt="Facebook logo" className="link" loading="lazy" title="Seuraa minua Facebookissa!" width="24" height="24" />
                            </a>
                            <a href="https://www.instagram.com/mrmoonstoner666/" target="_blank" rel="noreferrer" title="Henkilökohtainen Instagram">
                                <img src={Instagram} alt="Instagram logo" className="link" loading="lazy" title="Seuraa minua Instagramissa!" width="24" height="24" />
                            </a>
                            <a href="https://www.linkedin.com/in/arttu-stenius/" target="_blank" rel="noreferrer" title="Seuraa minua LinkedInissä">
                                <img src={Linkedin} alt="Linkedin logo" className="link" loading="lazy" title="Seuraa minua Linkedinissä!" width="24" height="24" />
                            </a>
                            <a href="https://github.com/arttustenius" target="_blank" rel="noreferrer" title="Github sivuni">
                                <img src={Github} alt="Github logo" className="link" loading="lazy" title="Seuraa minua Githubissa!" width="24" height="24" />
                            </a>
                        </div>
                    </form>
                </div>
            </section>
        </section>   
    );
};

export default Contact;
