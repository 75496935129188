import React, { useEffect } from "react";
import UIDesign from '../../assets/ui-desing.webp';
import WebDesign from '../../assets/vscode.webp';
import AppDesign from '../../assets/ai-icon.webp';
import MediaDesign from '../../assets/Musiikki_media.webp';
import AOS from "aos";
import "aos/dist/aos.css";

const Skills = () => {

    useEffect(() => {
        AOS.init({
          duration: 1000,
          easing: "ease-out-cubic",
        });
      }, []);
    
    return (
        <section id='skills'>
            <span title="Osaaminen" className="skillTitle">Osaaminen</span>
            <span className="skillDesc">Olen hyödyntänyt töissäni mm. näitä työkaluja ja ohjelmistoja.</span>

            <div data-aos="fade-right" className="skillBars">
                <div className="skillBar">
                    <a href="https://www.figma.com/" target="_blank" rel="noreferrer">
                        <img src={UIDesign} alt="UI/UX icon" className="skillBarImg" loading="lazy" title="UI/UX Osaaminen" width="512" height="512" />
                    </a>
                    <div className="skillBarText">
                        <h2 title="UI/UX">UI/UX</h2>
                        <p title="Figma, Draw.io, Canva & Adobe Photoshop.">
                            <a href="https://www.figma.com/" target="_blank" rel="noreferrer">Figma</a>, 
                            <a href="https://app.diagrams.net/" target="_blank" rel="noreferrer"> Draw.io</a>, 
                            <a href="https://www.canva.com/" target="_blank" rel="noreferrer"> Canva</a>, 
                            <a href="https://www.adobe.com/products/photoshop.html" target="_blank" rel="noreferrer"> Adobe Photoshop</a>
                        </p>
                    </div>
                </div>
            </div>

            <div data-aos="fade-left" className="skillBars">
                <div className="skillBar">
                    <a href="https://code.visualstudio.com/" target="_blank" rel="noreferrer">
                        <img src={WebDesign} alt="WebDesing icon" className="skillBarImg" loading="lazy" title="Web-design osaaminen" width="512" height="512" />
                    </a>
                    <div className="skillBarText">
                        <h2 title="Web-design">Web-design</h2>
                        <p title="Visual Studio Code, Wordpress, React, Bootstrap, Tailwind CSS.">
                            <a href="https://code.visualstudio.com/" target="_blank" rel="noreferrer">Visual Studio Code</a>, 
                            <a href="https://wordpress.org/" target="_blank" rel="noreferrer"> Wordpress</a>, 
                            <a href="https://reactjs.org/" target="_blank" rel="noreferrer"> React</a>, 
                            <a href="https://getbootstrap.com/" target="_blank" rel="noreferrer"> Bootstrap</a>, 
                            <a href="https://tailwindcss.com/" target="_blank" rel="noreferrer"> Tailwind CSS</a>
                        </p>
                    </div>
                </div>
            </div>
            <div data-aos="fade-right" className="skillBars">
                <div className="skillBar">
                    <a href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                        <img src={AppDesign} alt="Tekoäly" className="skillBarImg" loading="lazy" title="Tekoäly" width="512" height="512" />
                    </a>
                    <div className="skillBarText">
                        <h2 title="Tekoäly">Tekoäly</h2>
                        <p title="ChatGPT, Gemini, Ideogram, Artflow, Leonardo.ai, Adobe Firefly.">
                            <a href="https://chat.openai.com/" target="_blank" rel="noreferrer">ChatGPT</a>, 
                            <a href="https://gemini.com/" target="_blank" rel="noreferrer"> Gemini</a>, 
                            <a href="https://www.ideogram.ai/" target="_blank" rel="noreferrer"> Ideogram</a>, 
                            <a href="https://www.artflow.ai/" target="_blank" rel="noreferrer"> Artflow</a>, 
                            <a href="https://www.leonardo.ai/" target="_blank" rel="noreferrer"> Leonardo.ai</a>, 
                            <a href="https://firefly.adobe.com/" target="_blank" rel="noreferrer"> Adobe Firefly</a>
                        </p>
                    </div>
                </div>
            </div>
            <div data-aos="fade-right" className="skillBars">
                <div className="skillBar">
                    <a href="https://www.presonus.com/products/Studio-One" target="_blank" rel="noreferrer">
                        <img src={MediaDesign} alt="Musiikki ja video" className="skillBarImg" loading="lazy" title="Musiikki ja video" width="512" height="512" />
                    </a>
                    <div className="skillBarText">
                        <h2 title="Musiikki & video">Musiikki & video</h2>
                        <p title="Presonus Studio One, Corel VideoStudio, Adobe Premiere Pro.">
                            <a href="https://www.presonus.com/products/Studio-One" target="_blank" rel="noreferrer">Presonus Studio One</a>,
                            <a href="https://suno.com/" target="_blank" rel="noreferrer"> Suno</a>,
                            <a href="https://www.videostudiopro.com/en/" target="_blank" rel="noreferrer">  Corel VideoStudio</a>, 
                            <a href="https://www.adobe.com/products/premiere.html" target="_blank" rel="noreferrer"> Adobe Premiere Pro</a>
                        </p>
                    </div>
                </div>
            </div>
            
        </section>
    )
}

export default Skills;
