import React from "react";  
import { Routes, Route } from "react-router-dom";
import './style.css';

// Pages

import Home from "./Pages/Home/Home";
import NotFound from "./Pages/NotFound/NotFound";

// Components

import Navbar from "./components/Navbar/navbar";


function App() {

  return (
      <div className="App">

        <Navbar />
   
        <div className="container">

          <Routes>

            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<Home />} />
            
          </Routes>
        
        </div>

      </div>

  );
}

export default App;
