import { Link } from "react-router-dom";
import styles from './NotFound.module.css'; // Käytä CSS Modulea

export default function NotFound() {
    return (
        <div className={styles.notFoundContainer}>
            <h1 className={styles.heading}>Sivua ei löydy!</h1>
            <p className={styles.paragraph}>
                Valitettavasti etsimääsi sivua ei löytynyt. Se saattaa olla poistettu tai siirretty.
            </p>
            <p className={styles.paragraph}>
                Älä huoli! Voit palata <Link to="/">etusivulle</Link> ja jatkaa selaamista.
            </p>
        </div>
    );
}
