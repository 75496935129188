import React, { useState } from "react";
import Logo from '../../assets/TRIBAL_TATTOO 7.webp';
import { Link } from 'react-scroll';
import contactImg from '../../assets/contactimg.webp';
import menu from '../../assets/menuicon.webp';
import { motion } from "framer-motion";

const Navbar = () => {
    const [showMenu, setShowMenu] = useState(false);

    return (
        <nav className="navbar">
            <img src={Logo} alt="Logo" className="logo" title="Logo" />
            
            <div className="desktopMenu">
                <Link title="Koti" activeClass='active' to='intro' spy={true} smooth={true} offset={-50} duration={500} className="desktopMenuListItem">Koti</Link>
                <Link title="Osaaminen" activeClass='active' to='skills' spy={true} smooth={true} offset={-50} duration={500} className="desktopMenuListItem">Osaaminen</Link>
                <Link title="Portfolio" activeClass='active' to='works' spy={true} smooth={true} offset={-50} duration={500} className="desktopMenuListItem">Portfolio</Link>
                <Link title="Kielet" activeClass='active' to='languages' spy={true} smooth={true} offset={-50} duration={500} className="desktopMenuListItem">Kielet</Link>
            </div>

            <motion.button whileTap={{ scale: 0.85 }} className="desktopMenuBtn" onClick={() => {
                document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
            }}>
                <img src={contactImg} alt="Ota yhteyttä nappi" className="desktopMenuImg" />Ota yhteyttä!
            </motion.button>

            <img src={menu} alt="Menu" title="Menu" className='mobMenu' onClick={() => setShowMenu(!showMenu)} />

            {showMenu && (
                <div className="navMenu">
                    <Link activeClass='active' to='intro' spy={true} smooth={true} offset={-50} duration={500} className="listItem" onClick={() => setShowMenu(false)}>Koti</Link>
                    <Link activeClass='active' to='skills' spy={true} smooth={true} offset={-50} duration={500} className="listItem" onClick={() => setShowMenu(false)}>Osaaminen</Link>
                    <Link activeClass='active' to='works' spy={true} smooth={true} offset={-50} duration={500} className="listItem" onClick={() => setShowMenu(false)}>Portfolio</Link>
                    <Link activeClass='active' to='languages' spy={true} smooth={true} offset={-50} duration={500} className="listItem" onClick={() => setShowMenu(false)}>Kielet</Link>
                    <Link activeClass='active' to='contact' spy={true} smooth={true} offset={-50} duration={500} className="listItem" onClick={() => setShowMenu(false)}>Kontakti</Link>
                </div>
            )}
        </nav>
    );
};

export default Navbar;
