import React, { useEffect } from "react";
import bg from '../../assets/logo800px.webp';
import AOS from "aos";
import "aos/dist/aos.css";
import backgroundVideo from '../../assets/header_video6.mp4'

const Intro = () => {

    useEffect(() => {
        AOS.init({
          duration: 1000,
          easing: "ease-out-cubic",
        });
      }, []);
   
    return (
        
        <section id="intro">

            <div className="site-title-container">
                <h1 title="Arttu Stenius portfolio">Arttu Stenius portfolio</h1>
            </div>

            <video autoPlay muted loop id="videoScreen">
                <source src={backgroundVideo} />
            </video>

            <div className="introContent">

                <span title="Hei!" className="hello">Hei!</span>
                
                <span title="Minä olen Arttu ohjelmistokehittäjäopiskelija" className="introtext">Minä olen <span className="introName">Arttu</span> <br /> ohjelmistokehittäjäopiskelija </span>

                <p title="Keski-Uudeltamaalta, Keravalta. Suunnittelen ja luon käyttäjäystävällisiä responsiivisia verkkosivuja kaunista grafiikkaa unohtamatta. " className="introPara">Keski-Uudeltamaalta, Keravalta. <br /> <br />

                Suunnittelen ja luon käyttäjäystävällisiä <br />responsiivisia verkkosivuja <br /> kaunista grafiikkaa <br /> unohtamatta.<br /><br /></p>
                
            </div>  

                <img src={bg} alt="Arttu stenius profiili kuva" className="bg" title="Arttu Stenius" />

        </section>

    )
}

export default Intro;