import React, { Component } from 'react';

// Components

import Navbar from '../../components/Navbar/navbar';
import Intro from '../../components/Intro/intro';
import Skills from '../../components/Skills/skills';
import Works from '../../components/Works/works';
import Contact from '../../components/Contact/contact';
import Footer from '../../components/Footer/footer';


export default function Home() {
    
    return (

        <>
        
          <Navbar />
          <Intro />
          <Skills />
          <Works />
          <Contact />
          <Footer />
          
        </>  
    );  
};