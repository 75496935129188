import React from "react";

const Footer = () => {
    
    return (
        <footer className='footer'>
        
        <p title="Copyright &#169; 2024 Arttu Stenius. Kaikki oikeudet pidetään.">Copyright &#169; 2024 Arttu Stenius. Kaikki oikeudet pidetään.</p>

        </footer>
    );
}

export default Footer;